define(['app', 'siteObj', 'purchaseScript'], ( app, siteObj, purchaseScript) => {

  const shoeSizeMe = () => {
    const component = {};

    var config = {
      productVariation: 'productVariations/variationData',
      ssmVariationReceived: 'ssmVariationReceived'
    };

    const getLastAddedItemData = function (simpleBasket) {
      let lastAddedItem = simpleBasket.lastAddedItem;

      let data = {
        worthValue: lastAddedItem.worthValue,
        productId: lastAddedItem.productId,
        productTitle: lastAddedItem.productTitle,
        productUrl: lastAddedItem.productURL,
        productQuantity: simpleBasket.lastAddedQuantity,
        productQuantityRequested: simpleBasket.lastAddedQuantityRequested,
        productPrice: lastAddedItem.price,
        basketTotalPrice: simpleBasket.totalprice,
        recommendations: simpleBasket.recommendations,
        basketTotalItems: simpleBasket.totalUnits,
        message: simpleBasket.message,
        lastAddedMaxPerOrder: lastAddedItem.maxPerOrder,
        loyaltySchemePoints: simpleBasket.loyaltySchemePoints
      };

      let imageURL = '';
      if (lastAddedItem.productImages && lastAddedItem.productImages.largeproduct) {
        imageURL = lastAddedItem.productImages.largeproduct;
      }
      data.productImageUrl = imageURL;
      return data;
    };

    var _addedToCart = async (recommendation) => {
      const queryObj = getQueryObject(recommendation);

      if (queryObj && Object.keys(queryObj).length > 0) {

        app.ajax.get({
          url: '/basketinterface.json?' + querify(queryObj),
          success: addToBasketSuccess,
          error: addToBasketError
        });
      }
    }

    const _init = (element) => {
      component.element = element;

      if (component.element) {
        component.parentElement = component.element.parentElement.parentElement;
        component.buyButtonElement = component.parentElement.querySelector("[data-component='productAddToBasket']");
        component.buyButtonParentElement = component.buyButtonElement.parentElement.parentElement;
        component.fromWishlist = component.buyButtonParentElement.getAttribute('data-from-wishlist');
        component.wishlistPage = component.buyButtonParentElement.getAttribute('data-wishlist-page');
        component.allVariations = component.buyButtonParentElement.querySelectorAll('.individualVariation');
        component.shoeSizeMeID = siteObj.config.shoeSizeMeID;
        component.shoeSizeMeLocale = siteObj.config.shoeSizeMeLocale;
        component.shoeSizeMeScale = siteObj.config.shoeSizeMeScale;

        component.quantity = {
          value: 1,
          max: 99,
          inBasket: 0,
        };
      }

      if (component.wishlistPage === 'true') {
        component.variationReceived(
          component.buildVariations());
      } else {
        var variationSubscribe = app.subscribe(config.productVariation, component.variationReceived);
        component.checkSubscribeMessages(variationSubscribe, component.variationReceived);
      }

      app.subscribe('BasketItemAdded', function(data) {
        purchaseScript().update(data.simpleBasket.basketItems);
      });

      app.subscribe(config.ssmVariationReceived, function() {
        if(component.recommendation) {
          component.addedToCart(component.recommendation);
          component.recommendation = '';
        }
      });

      var ssm_addedToCart = function (recommendation) {
        if (recommendation) {
          component.recommendation = {
            shoeId: recommendation.shoeId,
            size: recommendation.size.uk
          }
          component.setSize(component.recommendation);
        }
      };

      var postData = async () => {
        const response = await fetch("https://shoesize.me/api/config/integrity",
          {method: 'GET'})

        return response.json();
      }

      (function (d, t, s) {

        postData().then((integrity) => {
          window.ssm_addedToCart = ssm_addedToCart;
          var shopId = component.shoeSizeMeID;
          var shoeId = window.dataLayer[0].productDetails[0].productSKU;
          var locale = component.shoeSizeMeLocale;
          var scale  = component.shoeSizeMeScale;
          // Do not modify
          var a = d.createElement(t);
          var f = d.getElementsByTagName(t)[0];
          a.async = 1;
          a.src = s;
          a.crossOrigin = 'anonymous';
          a.integrity = integrity.loader_integrity;
          a.text ='{shopID:"'+shopId+'",shoeID:"'+shoeId+'",locale:"'+locale+'",scale:"'+scale+'",' +
            'inCart: function(recommendation){if(typeof ssm_addedToCart == "function"){ ssm_addedToCart(recommendation)}}}';
          f.parentNode.insertBefore(a, f);
        });
      })(document, 'script', 'https://shoesize.me/assets/plugin/loader.js');

      return component;
    };

    var _buildVariations = function () {
      const variationData = {
        variationLength: component.allVariations.length,
        variations: []
      };

      for (var i = 0; i < component.allVariations.length; i++) {
        var thisVariationDropdown = component.allVariations[i];
        if (thisVariationDropdown.getAttribute('data-variation-id') === '-1') {
          return {
            variationLength: component.allVariations.length,
            variations: []
          };
        }
        variationData.variations.push(thisVariationDropdown.getAttribute('data-variation-id'));
      }

      return variationData;
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    const addToBasketSuccess = function (data) {
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount');

      let jsonData = JSON.parse(data);
      let modalData = getLastAddedItemData(jsonData.simpleBasket);

      app.publish('productQuickbuy/hideModal', '', false);
      app.publish('addedToBasketModal/productAdded', modalData, false);
      app.publish('BasketItemAdded', jsonData);

      if (jsonData.simpleBasket.lastAddedItem) {
        let lastAddedItemSku = jsonData.simpleBasket.lastAddedItem.productId;
        if (window.dataLayer.length) {
          window.dataLayer[0].lastAddedItemSku = lastAddedItemSku.toString();
        }
      }
    };

    const addToBasketError = function () {
      app.publish('productQuickbuy/stopLoading', '', false);
      app.publish('productQuickbuy/showError', '', false);
      app.publish('addedToBasketModal/showError', '', false);
    };

    var _variationReceived = function({variationLength, variations, isVariationPersonalisable}) {
      component.variationsObject = {
        variationLength,
        variations,
        isVariationPersonalisable
      };

      app.publish(config.ssmVariationReceived);
    };

    var _setSize = function(recommendation) {

      component.sizeSelector = component.parentElement.querySelectorAll(".athenaProductVariationsOption");
      recommendation.size = parseFloat(recommendation.size);
      if (component.sizeSelector.length < 1) {
        return
      }
      for (var i=0; i < component.sizeSelector.length; i++){
        let variationSize = parseFloat(component.sizeSelector[i].textContent.trim());

        if (variationSize === recommendation.size) {
          let matchingSize = component.sizeSelector[i]
          matchingSize.setAttribute('data-selected', '');
          let e = new Event("click");
          matchingSize.dispatchEvent(e);
          break;
        }
      }
    }

    var querify = function(stateObject) {
      var params = [];
      for (var param in stateObject) {
        if (stateObject.hasOwnProperty(param)) {
          var value = stateObject[param];
          if (param.indexOf('variation') === 0 && param !== 'variationLength') {
            value = param + ':' + value;
          }
          params.push(param + '=' + value);
        }
      }
      return params.join('&');
    };

    const getQueryObject = function (item) {
      var val1 = {
        productId: item.shoeId,
        siteId: siteObj.siteID,
        siteDefaultLocale: siteObj.siteDefaultLocale,
        siteSubsiteCode: siteObj.subsiteCode,
        variationLength: null,
        quantity: null,
        fromWishlist: component.fromWishlist
      }

      var transformedStateObject = transformVariations(val1);
      transformedStateObject = transformQuantity(transformedStateObject);

      return transformedStateObject;
    };

    var transformVariations = function(stateObject) {
      var variationsData = component.variationsObject;

      if (variationsData && variationsData.variations) {
        stateObject.variationLength = variationsData.variationLength;

        for (var i = 0; i < variationsData.variations.length; i++) {
          var variationValue = variationsData.variations[i];
          stateObject['variation' + (i + 1)] = variationValue;
        }
      }

      return stateObject;
    };

    var transformQuantity = function(stateObject) {
      stateObject.quantity = (component.quantity) ? component.quantity.value : 1;
      return stateObject;
    };


    component.init = _init;
    component.variationReceived = _variationReceived;
    component.buildVariations = _buildVariations;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.addedToCart = _addedToCart;
    component.setSize = _setSize;

    return component;
  };

  return shoeSizeMe;
});
